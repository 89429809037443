import React, {useContext, useRef, useState} from "react";
import {observer} from "mobx-react";

import classNames from "classnames";
import styles from "./styles/AboutBlockCS.module.scss";

import BlockTitle from "../_BlockTitle";
import {MessageBlock} from './MessageBlock';
import {AboutMediaInset} from './AboutMediaInset';
import {StoreContext} from "../../../../stores/StoreLoader";
import WatsonApi from "../../../../backends/WatsonApi";
import {runInAction} from "mobx";
import NotificationManager from "../../../notifications/NotificationManager";
import {CSSTransition} from "react-transition-group";
import dynamic from "next/dynamic";
import FontAwesome from "../../../utilities/FontAwesome";
import {EditDropDownItem} from "../EditDropDown";
import SaveCancelButtons from "../SaveCancelButtons";

const MediaEditor = dynamic(() => import("./AboutMediaEditor"));

const transitionClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

const AboutBlock = observer((props: {
    blockObj: IAboutTerrace
    aboveTheFold: boolean,
}) => {
    const {gridStore} = useContext(StoreContext);
    const transitionRefText = useRef(null);
    const transitionRefImage = useRef(null);

    const [mediaObject, setMediaObject] = useState<MediaObject>(props.blockObj.json_data.about?.media || {
        type: "iframe",
        url: "",
    })
    const [message, setMessage] = useState(props.blockObj.json_data.about?.text);
    const [editMode, setEditMode] = useState(false);

    const messageRef = useRef<HTMLDivElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    async function _setEditing(value, cancel) {
        if (value){
            setEditMode(value);
        }
        else if (!cancel) {
            await handleSave();
        }
        else if (cancel){
            setMediaObject(props.blockObj.json_data.about?.media || {
                type: "iframe",
                url: "",
            })
            setMessage(props.blockObj.json_data.about?.text);
            setEditMode(false);
        }
    }

    const handleSave = async () => {
        try {
            const submitData = {
                about: {
                    text: message,
                    media: {},
                },
            }
            if (mediaObject) {
                submitData.about.media = mediaObject;
            }
            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_terraces_partial_update({
                organization_pk: props.blockObj.organization_id,
                id: props.blockObj.id,
                data: {
                    json_data: submitData,
                },
            })
            setEditMode(false);
            runInAction(() => {
                const updatedTerrace = JSON.parse(response.data);
                gridStore.blocks.set(updatedTerrace.id, updatedTerrace);
            });
            NotificationManager.success("Terrace successfully saved!");
        } catch (e) {
            setMessage(props.blockObj.json_data.about?.text);
            NotificationManager.error("Something went wrong! Please try again.");
        }
    };

    const aboutBlockContainerClassName = classNames({
        [styles.aboutBlockContainer]: true,
        [styles.aboutBlockContainerMessageFullWidth]: !(mediaObject?.url || editMode),
        [styles.aboutBlockContainerEditMode]: editMode,
    })

    const aboutBlockDropDownItems: EditDropDownItem[] = [
        {
            title: 'Edit',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-pencil-alt"/>,
            onClick: () => _setEditing(!editMode, true),
        },
    ]

    const valid = mediaObject?.type === "image" ? !!(mediaObject?.alt) : true;

    const messageTransitionContainer = classNames({
        [styles.messageTransitionContainer]: true,
    })

    return (
        <section>
            <div className={aboutBlockContainerClassName} ref={containerRef}>
                <div style={{position: "relative"}}>
                    <div className={messageTransitionContainer} ref={messageRef}>
                        <CSSTransition nodeRef={transitionRefText} appear classNames={transitionClassNames} in={props.aboveTheFold} timeout={500}>
                            <div ref={transitionRefText} className={styles.messageContainer}>
                                <div className={styles.titleContainer}>
                                    <BlockTitle blockObj={props.blockObj}
                                                editing={editMode}
                                                setEditing={_setEditing}
                                                aboveTheFold={props.aboveTheFold}
                                                dropDownItems={aboutBlockDropDownItems}
                                                allowSave={valid}
                                                showViewMore={false}
                                    />
                                </div>
                                <MessageBlock
                                    editMode={editMode}
                                    messageRef={messageRef}
                                    message={message}
                                    setMessage={setMessage}
                                />

                            </div>
                        </CSSTransition>
                    </div>
                </div>
                <div className={styles.videoTransitionContainer}>
                    <CSSTransition nodeRef={transitionRefImage} appear classNames={transitionClassNames} in={props.aboveTheFold} timeout={500}>
                        <div ref={transitionRefImage} className={styles.videoContainer}>
                            {editMode && <div className={styles.editMediaContainer}><MediaEditor
                                mediaObject={mediaObject}
                                setMediaObject={setMediaObject}
                                terraceObj={props.blockObj}
                            /></div>}
                            {(mediaObject?.url && !editMode) && <AboutMediaInset blockObj={props.blockObj}/>}
                        </div>
                    </CSSTransition>
                </div>
            </div>
            {editMode &&
                <SaveCancelButtons editMode={editMode} setEditing={_setEditing} disabled={!valid}/>
            }
        </section>
    );
});

export default AboutBlock;
