import {ReactElement, RefObject, SetStateAction, useContext} from "react";
import {observer} from 'mobx-react';
import Markdown from "../../../utilities/Markdown";

import classNames from "classnames";
import styles from "./styles/MessageBlock.module.scss";
import SlateEditor from "../../../editors/MarkdownEditor";
import {StoreContext} from "../../../../stores/StoreLoader";

export const MessageBlock = observer((props: {
    editMode: boolean,
    messageRef?: RefObject<HTMLDivElement | null>,
    message?: string,
    setMessage: SetStateAction<any>,
}) => {
    const {userStore} = useContext(StoreContext);

    const messageBlockClassName = classNames({
        [styles.messageBlock]: true,
    });
    const messageContainerClassName = classNames({
        [styles.messageContainer]: true,
    })

    let content: ReactElement | string | undefined = props.message, jsx;
    if (!props.message && userStore.isEditor && !props.editMode) {
        content = <p>Add some text!</p>
    }

    if (props.editMode) {
        jsx = <>
            <SlateEditor editorClassName={styles.editor} handleTextChange={v => props.setMessage(v)} value={props.message || ""}/>
        </>
    } else {
        jsx = <div className={messageBlockClassName}>
            <Markdown source={content} withStyles={true}/>
        </div>
    }

    return (
        <div className={messageContainerClassName}>
            {jsx}
        </div>
    );
})
